nav .uk-navbar-container .uk-navbar {
    background: white !important;
}

.uk-navbar-nav > li > a {
    color: white !important;
}

.uk-navbar-dropdown-nav > li > a {
    color: white !important;
}